import { template as template_7ef8823a41ff407b8951abaea6114de4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7ef8823a41ff407b8951abaea6114de4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
