import { template as template_b5082bc9c35849219eb7e5b5117edd4b } from "@ember/template-compiler";
const FKLabel = template_b5082bc9c35849219eb7e5b5117edd4b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
