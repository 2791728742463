import { template as template_57d3709a56b047a4b7de002e15139e68 } from "@ember/template-compiler";
const WelcomeHeader = template_57d3709a56b047a4b7de002e15139e68(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
